<template>
  <div id="app" class="app" ref="app">

    <Transition name="fade">
      <router-view />
    </Transition>

    <Navigator />

  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Navigator from '@/components/Navigator'
export default {
  components: {
    Navigator
  },
  computed: {
    ...mapGetters(['isFullscreen'])
  },
  watch: {
    isFullscreen: {
      immediate: true,
      handler() {
        if (this.isFullscreen) {
          let frame = this.$refs.app.requestFullscreen()
        } else {
          if (document.exitFullscreen) {
            document.exitFullscreen()
          }
        }
      }
    }
  },
  methods: {
    exitFullscreen() {
      if (!document.fullscreenElement && !document.webkitIsFullScreen && !document.mozFullScreen && !document.msFullscreenElement) {
        this.$store.dispatch('toggleFullscreen')
      }
    }
  },
  mounted() {
    document.addEventListener('fullscreenchange', this.exitFullscreen)
    document.addEventListener('webkitfullscreenchange', this.exitFullscreen)
    document.addEventListener('mozfullscreenchange', this.exitFullscreen)
    document.addEventListener('MSFullscreenChange', this.exitFullscreen)

    document.addEventListener('keyup', event => {

      if (this.$route.name !== 'Training') return false

      let current = parseInt(this.$route.params.slide.split('-')[1])
      if (event.keyCode === 37) {
        let next = (current === 1) ? (this.$store.state.slides.length) : (current - 1)
        this.$router.push({ name: 'Training', params: { slide: `example-${next}` }})
      }
      if (event.keyCode === 39) {
        let next = (current === this.$store.state.slides.length) ? 1 : (current + 1)
        this.$router.push({ name: 'Training', params: { slide: `example-${next}` }})
      }
    })
  }
}
</script>
