<template>
  <div class="training" :class="{ 'training--fullscreen': isFullscreen }" ref="training">

    <div class="image">
      <img :src="getImage(slideName)" alt="Demo example" class="">
    </div>

  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['slides', 'isFullscreen']),
    slideName() {
      return (this.$route.params.slide && this.slides.indexOf(this.$route.params.slide) > 0) ? this.$route.params.slide : 'example-1'
    }
  },
  methods: {
    getImage(slide) {
      let images = require.context('../assets/images/screens/', false, /\.png$/)
      return images(`./${slide}.png`)
    }
  }
}
</script>
