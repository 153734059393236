
<template>

  <div class="navigator" :class="{ 'navigator--visible': !isFullscreen }" v-show="$route.name !== 'Playground'">

    <div class="navigator__general">

      <router-link :to="{ name: 'Training', params: { slide: 'example-1' }}">
        <img src="@/assets/images/elements/play.svg" class="navigator__icon" alt="home">
      </router-link>
      <router-link :to="{ name: 'Playground' }">
        <img src="@/assets/images/elements/playground.svg" class="navigator__icon" alt="playground">
      </router-link>

    </div>
    <div class="navigator__examples" :class="{ 'navigator__examples--visible': $route.name === 'Training' && !isFullscreen }">

      <router-link v-for="slide of slides" :to="{ name: 'Training', params: { slide }}" :key="slide">
        <img :src="getImage(slide)" alt="slide-demo" class="navigator__examples-item" :class="{ 'navigator__examples-item--active': $route.params.slide === slide }" />
      </router-link>

    </div>
    <div class="navigator__settings">

      <a href="#" @click.prevent="toggleFullscreen">
        <img src="@/assets/images/elements/fullscreen.svg" class="navigator__icon" alt="settings">
      </a>

    </div>

  </div>

</template>
<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['slides', 'isFullscreen'])
  },
  methods: {
    toggleFullscreen() {
      this.$store.dispatch('toggleFullscreen')
    },
    getImage(slide) {
      let images = require.context('../assets/images/screens/', false, /\.png$/)
      return images(`./${slide}.png`)
    }
  }
}
</script>
