import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  getters: {
    slides: state => state.slides,
    isFullscreen: state => state.fullscreen
  },
  state: {
    fullscreen: false,
    slides: ['example-1', 'example-2', 'example-3', 'example-4', 'example-5']
  },
  mutations: {
    TOGGLE_FULLSCREEN(state) {
      state.fullscreen = !state.fullscreen
    }
  },
  actions: {
    toggleFullscreen({ commit }) {
      commit('TOGGLE_FULLSCREEN')
    }
  }
})
