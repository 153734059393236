import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from '@/views/Home'
import Calibration from '@/views/Calibration'
import Instructions from '@/views/Instructions'
import Training from '@/views/Training'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/instructions',
    name: 'Instructions',
    component: Instructions
  },
  {
    path: '/playground',
    name: 'Playground',
    component: Calibration
  },
  {
    path: '/training/:slide?',
    name: 'Training',
    component: Training
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
