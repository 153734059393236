<template>
  <div class="calibration" @click.prevent="gotoHomepage">
    <img src="@/assets/images/elements/playground-marker.png" />
  </div>
</template>
<script>
export default {
  methods: {
    gotoHomepage() {
      this.$router.push({ name: 'Home' })
    }
  }
}
</script>
